import { useRouter } from "next/router"
import { useEffect } from "react"

// expressサーバーでルートからリダイレクトしたかったが上手くいかなかったので、ルートパスへのリダイレクトはここで制御する。
const Root = () => {
    const router = useRouter()

    useEffect(() => {
        router.push("/entry")
    })

    return <></>
}

export default Root